// /!\ extending array function could clash with every library js lib use  /!\
//USE IT CAREFULLY
Array.prototype.delete = function(element){
  return this.filter(e => !e.match(element));
}

Array.prototype.add = function(element){
  this.push(element);
  return this;
}

Array.prototype.toogle = function(element){
  return this.includes(element) ? this.delete(element) : this.add(element);
}

Array.prototype.toogleSave = function(element){
  if(this.includes(element)){
    this.deleteSave(element);
    return false;
  } else {
    this.push(element);
    return true;
  }
}

Array.prototype.has = function(query){
  return this.count(query) > 0;
}

Array.prototype.count = function(query){
  if (query == undefined || query == null) return this.length - this.filter(e=>e).length ;
  else return this.filter(e=>e.match(query)).length;
}

Array.prototype.deleteSave = function(element){
  let index = -1;
  if(element) index = this.indexOf(element);
  else { for(i=0;i<this.length;i++){ if(this[i] == undefined || this[i] == null){ index = i; break; } } };

  if(index >= 0) return this.splice(index, 1)[0];
  else return undefined;
}

Array.prototype.deleteIf = function(regEx){
  return this.filter(e=> !e.match(regEx))
}

Array.prototype.deleteIfSave = function(regEx){
  if(regEx != undefined || regEx != null){
    this.forEach( (e,i) => {
      if(e.match(regEx)) delete this[i];
    });
  }
  let deleted = this.count(undefined);
  for(i=0; i < deleted;i++)this.deleteSave(undefined);
  return deleted;
}

Array.prototype.uniq = function(){
  let a = []
  this.forEach( (e) => {if(!a.includes(e))a.push(e);});
  return a;
}

Array.prototype.uniqSave = function(){
  let a = []
  this.forEach( (e,i) => {
    if(!a.includes(e))a.push(e);
    else delete this[i];
  });

  let deleted = this.count(undefined);
  for(i=0; i < deleted;i++)this.deleteSave(undefined);

  return deleted;
}
