
function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}

function send(email){
  fetch("/newsletters/add", {
    method: "POST",
    headers: { 'Content-Type': 'application/json',
      "X-CSRF-Token": getMetaValue("csrf-token")},
    body: JSON.stringify({email: email}),
    credentials: 'same-origin',
  }).then((response) =>{
    response.json().then((data)=> {
      M.toast({'html': data.msg});
    });
  });
}

function newsletterFromButton(me){
  send(me.target.parentElement.querySelector("input").value);
}

function newsletterFromInput(e){
  send(e.target.querySelector("input").value);
  e.preventDefault();
  return false;
}

document.addEventListener('turbolinks:load', ()=>{
  if(document.querySelector("#newsletterEmailForm")){
    document.querySelectorAll(".btn-mail-newsletter").forEach((e, i) => {
      e.addEventListener('click',newsletterFromButton);
    });
    document.querySelector("#newsletterEmailForm").addEventListener('submit',newsletterFromInput);
    document.querySelector("#newsletterEmailButton").addEventListener('click',newsletterFromButton);
  }
});
