//this is a manual solution, but it's better to just carry the turbolinks id by tranfering history.state
document.addEventListener('turbolinks:load',function() {
   window.onpopstate = function() {
     if(history.state != null && history.state.backreload)location.reload();
   };
});


function removeParam(key){
  let newurl = removeParamFrom(window.location.href, key);
  history.state["backreload"] = true;
  window.history.replaceState(history.state,'',newurl);
}

function jumpTo(path){
  if(window.location.href != path) {
    window.location.href = path;
    return true;
  } else {
    return false;
  }
}

function removeParamFrom(origin_url, parameter) {
    //prefer to use l.search if you have a location/link object
    let url = origin_url.split("#")[0];
    let anchor = origin_url.split("#")[1];
    if(anchor == undefined) anchor = "";
    else anchor = "#" + anchor;

    let urlparts= url.split('?');
    if (urlparts.length>=2) {

        let prefix= encodeURIComponent(parameter)+'=';
        let pars= urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (let i= pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url= urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
        return url + anchor;
    } else {
        return url + anchor;
    }
}

function anchor(){
  let a  = getAnchor();
  if(a)return "#"+a;
  else return "";
}

function addParam(key, value) {
  if (history.replaceState) {
      // let newurl = window.location.protocol + "//" + window.location.host + search.pathname + '?myNewUrlQuery=1';
      let currentUrl = window.location.href.split("#")[0];
      //remove any param for the same key
      currentUrl = removeParamFrom(currentUrl, key);

      //figure out if we need to add the param with a ? or a &
      let queryStart;
      if(currentUrl.indexOf('?') !== -1){
          queryStart = '&';
      } else {
          queryStart = '?';
      }

      try{
        value = JSON.stringify(value);
      } catch(e){
        //dont care
      }

      let newurl = currentUrl + queryStart + key + '=' + value + anchor();
      history.state["backreload"] = true;
      window.history.replaceState(history.state,'',newurl);
  }
}

function getParamOr(key, or){
  let content = getParam(key);
  if(content == undefined) content = or;
  return content;
}

function getParam(key) {
    let lets = {}, hash;
    let hashes = window.location.href.split("#")[0].slice(window.location.href.indexOf('?') + 1).split('&');
    for(let i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        lets[hash[0]] = decodeURIComponent(hash[1]);
    }
    if(key !== undefined) {
      let value = lets[key];
      try {
        return JSON.parse(value);
      } catch(e){
        return value;
      }
    } else return lets;
}

function getAnchor(){
  let a = window.location.href.split("#")[1];
  if(a != undefined && a.includes("?"))a = a.split("?")[0];
  return a;
}

function addAnchor(a){
  let newurl = window.location.href.split("#")[0]+"#"+a;
  history.state["backreload"] = true;
  window.history.replaceState(history.state,'',newurl);
}

function removeAnchor(){
  let newurl = window.location.href.split("#")[0];
  history.state["backreload"] = true;
  window.history.replaceState(history.state,'',newurl);
}

function getShowId(){
  return parseInt(window.location.pathname.match(/([0-9]*)[\.a-zA-Z]*$/)[1]);
}

export default {
  getParam, getParamOr,addParam,removeParam,
  getAnchor,addAnchor,removeAnchor,
  jumpTo, getShowId
}
