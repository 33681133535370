import {search_channel} from "../../channels/search_channel";
import url from "../utils/url";
import bigmenu from "./bigmenu";
import categories from "./categories";

const URL_KEY = "search";

function modeProduct(){
  return !document.querySelector("#subSearch")
}

function switchToFilter(){
  document.querySelector("#navbarSearchForm").style.display = "none";
  document.querySelector("#navbarSearchLi").style.display = "block";
  document.querySelector("#nav-empty").style.display = "block";
  document.querySelector("#nav-link").style.display = "block";
  document.querySelector(".navbarFixed .nav-wrapper .right").style.display = "block";
}

function loadSearch(me){
  document.querySelector("#navbarSearchLi").style.display = "none";
  document.querySelector("#nav-empty").style.display = "none";
  document.querySelector("#nav-link").style.display = "none";
  document.querySelector("#navbarSearchForm").style.display = "block";
  document.querySelector("#navbarSearchForm input").focus();
  document.querySelector(".navbarFixed .nav-wrapper .right").style.display = "none";
  bigmenu.close();
  if( modeProduct() )
    document.querySelector("#navbarSearchFilterShow").style.display = "block";
  else document.querySelector("#navbarSearchFilterShow").style.display = "none";
}


function loadSearchFromUrl(){
  let surl = url.getParam(URL_KEY);
  if(surl != undefined){
    loadSearch();
    document.querySelector("#navbarSearchForm input").value = surl;
    search({currentTarget: { value: surl}});
  }
}

function unloadSearch(me){
  document.querySelector("#navbarSearchForm").style.display = "none";
  document.querySelector("#navbarSearchForm input").value = "";
  url.removeParam(URL_KEY);

  document.querySelector("#navbarSearch").innerHTML = "<i class='material-icons left'>search</i> Recherche";

  document.querySelector("#navbarSearchLi").style.display = "block";
  document.querySelector("#nav-empty").style.display = "block";
  document.querySelector("#nav-link").style.display = "block";

  document.querySelector(".navbarFixed .nav-wrapper .right").style.display = "block";

  let subSearch = document.querySelector("#subSearch")
  if(subSearch) subSearch.classList.remove("open");

  document.body.style.position = ''; // unblock scroll
}

let query = "";
function search(e){
  if(query != e.currentTarget.value){
    query = e.currentTarget.value;
    if( !modeProduct() ){ // this is for sub search
      search_channel.query(query, answer);
    } else { //this is for products search
      url.addParam(URL_KEY, query);
      document.querySelector("#navbarSearch").innerHTML = "<i class='material-icons left'>search</i>"+ " "+ query;
      search_channel.products(query, categories.menuState());
    }
  }
}
function  loadNavbarSearchForm() {
  let input_search = document.querySelector("#navbarSearchForm input");
  input_search.addEventListener('keyup', search);
  input_search.addEventListener('paste', search);

  let searchForm = document.getElementById('navbarSearchForm');
  if(searchForm){
    searchForm.addEventListener('keypress', function(event) {
      if(event.key == "Enter") {
        if(!modeProduct())url.jumpTo("/products");
        else document.querySelector("#navbarSearchForm input").blur();
        event.preventDefault();
      }
    });
  }
}


function answer(data){
  url.addParam(URL_KEY,data.text);
  document.querySelector("#suggestions").innerHTML = data.suggestions.join('');
  document.querySelector("#searchResultProductContent").innerHTML = data.products_results.join('');
  document.querySelector("#allProductSize").innerHTML = data.products_results_size;
  document.querySelector("#searchResultArticle").innerHTML = data.articles_results.join('');
  document.querySelector("#subSearch").classList.add("open");
  document.querySelector("#allProducts").setAttribute("href", "/products?search=\""+data.text+"\"");
  document.querySelector(".complete-search-suggestion").addEventListener('click',completeSearch);
  document.body.style.position = 'fixed'; // block scroll
}

function completeSearch(suggestion_link){
  let suggestion = document.querySelector(suggestion_link.target).getAttribute("search_for");
  document.querySelector("#navbarSearchForm input").value = suggestion;
  search({currentTarget: { value: suggestion}})
}

document.addEventListener('turbolinks:load', ()=> {
  if(document.querySelector("#navbarSearch")){
    document.querySelector("#navbarSearch").addEventListener('click', loadSearch);
    document.querySelector("#navbarSearchFilterShow").addEventListener('click', switchToFilter);
    loadSearchFromUrl();

    document.querySelector("#navbarSearchFormClose")
      .addEventListener('click', (me)=>{
        if(window.location.pathname == "/products")search({currentTarget: { value: ""}});
        unloadSearch(me);
      });
    let voile = document.querySelector('#voileNoirForSubSearch');
    if(voile) voile.addEventListener('click',unloadSearch);

    loadNavbarSearchForm();
  }
});
