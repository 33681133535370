// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from "@rails/actioncable"

// Specify a different URL to connect to
//createConsumer('https://ws.example.com/cable')

//Use a function to dynamically generate the URL
export default createConsumer()
