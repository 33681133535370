import { basket_channel } from "../channels/basket_channel";
import "./navbar/search";
import categories from "./navbar/categories";

function basketChange(data){
  document.querySelector(".basket-size").textContent = data.size;
}
basket_channel.listenToChange(basketChange);

let sideleft;
function initSideLeft() {
 sideleft = M.Sidenav.init(document.querySelector('.sidenav'), { });
 if(sideleft){
   document.querySelector(".sideleft-trigger").addEventListener('click',() => sideleft.open());
 }
}
document.addEventListener('turbolinks:load', initSideLeft);

function connectionModal(){

}
document.addEventListener('turbolinks:load', categories.load);

//init dropdown
document.addEventListener('turbolinks:load', ()=>{
  let dropdown = M.Dropdown.init(document.querySelector('#menu_more_trigger'));
  if(dropdown){
    dropdown.options.constrainWidth = false;
    dropdown.options.onOpenEnd = ()=>{
      dropdown.dropdownEl.style.left = (parseInt(dropdown.dropdownEl.style.left)-10)+"px"
    }
  }
});

export default {
  connectionModal,
}
