//global s'execute via un appel dans la page (donc après le load de la page)
//Contrairement à application.js qui lui est appeler dans le header
require("@rails/ujs").start() // 6kb of js //needed for delete method on link
require("materialize-css");

import 'javascript/src/navbar'
import cookie from 'javascript/src/utils/cookie.js';
import 'javascript/src/utils/newsletter.js';


// document.addEventListener('turbolinks:load', ()=>{
//   console.log("addEventListener('turbolinks:load");
//   //M.AutoInit();
// });

function isPath(path){ return window.location.pathname.match(path); }
function isUserMenu(){ return isPath(/\/users[a-z\/]*/); }

function setMenuUser() {
  //setActive value by looking @ url
  document.querySelectorAll(".user-menu").forEach((e, i) => {
    e.style.display = "block";
  });
  document.querySelectorAll(".shop-menu").forEach((e, i) => {
    e.style.display = "none";
  });
}

function setMenuHome() {
  //setActive value by looking @ query
  document.querySelectorAll(".user-menu").forEach((e, i) => {
    e.style.display = "none";
  });
  document.querySelectorAll(".shop-menu").forEach((e, i) => {
    e.style.display = "block";
  });
  ;
}

function setSideMenu(){
  if(isUserMenu()) setMenuUser();
  else setMenuHome();
  if(cookie.get("user_info") && cookie.get("user_info").role == "admin"){
    document.querySelectorAll(".admin-menu").forEach((e, i) => {
      e.style.display = "block";
    });
  }
}
document.addEventListener('turbolinks:load', setSideMenu);

function urlArgument(){
  let kvp = document.location.href.replace(document.location.origin,"")
    .replace(/^\/?\?/,"").split('&');
  return new Map(kvp.map(i => [i.split("=")[0], i.split("=")[1]]));
}

document.addEventListener("turbolinks:load", function() {scrollTo(0,0);});
