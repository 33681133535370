import cb from '../src/utils/channel_base';
import consumer from "./consumer";
import h from "../src/utils/helper";
const ChannelParent = require('./channel_parent').ChannelParent;

class SelectableListChannel extends ChannelParent {
  constructor(channel_name) {
    let self = super(channel_name);
    self.list = { change: [], query: [], get: []};
    return self;
  }

  on_received(data){
    switch (data.action) {
      case "delete":
      case "new":
      case "change":
      case "total":
      case "line_change":
      case "confirm_total":
        cb.execList(this.list.change,data);
        break;
      case "query":
        cb.execList(this.list.query,data);
        break;
      case "details":
        this.list.get[data.callback_id](data);
        break;
      default:
        //console.warn("dont know action: "+data.action);
    }

    super.on_received(data);
  }

  listenToChange(change) { cb.listenToList(change,this.list.change); }
  clearToChange(id){ cb.clearToList(id,this.list.change); }
  clearAllChange() { this.list.change = []; }

  listenToQuery(query) { cb.listenToList(query,this.list.query); }
  clearToQuery(id){ cb.clearToList(id,this.list.query); }
  clearAllQuery() { this.list.query = []; }

  query(text, query_number, limit, offset, object_id){
    this.channel.send({'action': 'query', 'text': text,
      'limit': limit, 'offset': offset, 'query_number': query_number, 'object_id': object_id});
  }

  get(id, callback){
    this.channel.send(Object.assign({'action': 'get', 'callback_id': this.list.get.push(callback)-1}, id));
  }

  create(params){
    this.channel.send({ 'action': 'create', params: params});
  }

  edit(params) {
    this.channel.send({'action': 'edit', 'params': params });
  }

  delete(ids){
    this.channel.send({'action': 'delete', ids: ids});
  }

  deleteImage(model_id){
    this.channel.send({'action': 'deleteElement', 'model_id': model_id, 'element': 'image_data'});
  }

}

export { SelectableListChannel };
