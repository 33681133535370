import cb from '../src/utils/channel_base';
import consumer from "./consumer"
const SelectableListChannel = require('./selectable_list_channel').SelectableListChannel;

let fav_list = [];
let callbacks = [];
class BasketChannel extends SelectableListChannel {

  constructor() {
    super("BasketChannel");
  }

  on_received(data){
    switch (data.action) {
      case "del_fav":
      case "new_fav":
        cb.execList(fav_list,data);
        break;
      case "pay":
        callbacks[data.callback_id-1](data);
        delete callbacks[data.callback_id-1];
        break;
      default:
        //SelectableListChannel.prototype.on_received.apply(data);
    }
    super.on_received(data);
  }

  listenToFav(change) { cb.listenToList(change,fav_list); }
  clearToFav(id){ cb.clearToList(id,fav_list); }
  clearAllFav() { fav_list = []; }

  add(product_id){ this.channel.send({'action': 'add', 'product_id': product_id}); }
  remove(beid){ this.channel.send({'action': 'remove', 'beid': beid}); }
  removeAll(){ this.channel.send({'action': 'remove_all'}); }
  update_size_rep(beid, key, value){ this.channel.send({'action': 'update_size_rep', beid: beid, key: key, value: value}); }
  update_size_rep_for_product(pid, key, value){ this.channel.send({'action': 'update_size_rep', product_id: pid, key: key, value: value}); }
  fav(product_id){ this.channel.send({'action': 'add_to_fav', product_id: product_id}); }
  unfav(product_id){ this.channel.send({'action': 'remove_from_fav', product_id: product_id}); }
  pay(data,callback){
    data['action']='pay';
    data['callback_id']=callbacks.push(callback)
    this.channel.send(data);
  }
  confirm(pi){
    this.channel.send({action: 'confirm', pi: pi});
  }
  confirmTotal(bid, total_stripe){

    if(this.isChannelUp){
      this.channel.send({action: 'confirm_total', bid: bid, total_stripe: total_stripe});
    } else {
      this.onChannelUp(() => {
        this.channel.consumer.connection.webSocket.onopen =  undefined;
        this.channel.send({action: 'confirm_total', bid: bid, total_stripe: total_stripe});
      });
    }
  }
}


export let basket_channel = new BasketChannel();
