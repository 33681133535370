import h from '../utils/helper';

let mobileView = "";
document.addEventListener('turbolinks:load',()=>{
  let modalSubCatNav = document.querySelector("#modalSubCatNav");
  if(modalSubCatNav)h.loadOnReady(M.Modal,modalSubCatNav, (v)=>{ mobileView = v;});
});

function open(){
  if(isDesktopView()) {
    document.querySelector("#subCatNav").classList.add("open");
  }
}

function toggle(){
  let subCatNav = document.querySelector("#subCatNav");
  if(subCatNav.classList.contains("open")){
    subCatNav.classList.remove("open");
  } else { subCatNav.classList.add("open"); }
}
document.addEventListener('turbolinks:load',() =>{
  document.querySelectorAll(".openBigMenu").forEach((e, i) => {
    e.addEventListener('click', a => toggle());
  });});

function close(){
  document.querySelector("#subCatNav").classList.remove("open");
}

function isDesktopView(){
  return document.querySelector("#nav-link").offsetHeight > 0;
}

function isMobileView(){
  return document.querySelector("#slide-out").offsetHeight > 0;
}

function isReopenClick(){
  return !isMobileView() && !isDesktopViewOpen();
}

function isDesktopViewOpen(){
  return document.querySelector("#subCatNav").classList.contains("open") ;
}

document.addEventListener('turbolinks:load', ()=>{
  let closeBtn = document.querySelector("#subCatNavClose");
  if(closeBtn) closeBtn.addEventListener('click', close);
});

export default {
  open, close, isReopenClick, isDesktopViewOpen, isMobileView
}
