
function listenToList(callback, list){
  if(typeof(callback) == "function")
    return list.push(callback);
  return undefined;
}

function clearToList(id, list){
  if( id < list.length )list[id] = undefined;
}

function execList(list,data){
  list.forEach( (act) => {
    if(act !== undefined)act(data);
  });
}

export default {
  listenToList, clearToList, execList
}
