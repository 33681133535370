
function get(name){
  let ret = undefined;
  document.cookie.split('; ').forEach(function(el) {
    if(el.startsWith(name)){
      let r = decodeURIComponent(el.replace(name+"=",""));
      if(r.match(/^\[|^\{/) != null)ret = JSON.parse(r);
      else ret = r;
    }
  });
  return ret;
}

function set(name, value){
  let ret = get(name) || {};
  Object.keys(value).forEach((key, i) => {
    ret[key]=value[key];
  });
  document.cookie = name+"="+encodeURIComponent(JSON.stringify(ret));
}

function clear(name){
  document.cookie = name+'=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
}

export default {
  set, get, clear
};
