import url from "../utils/url";
import h from '../utils/helper';
import bigMenu from './bigmenu';
import '../utils/array';
import { search_channel } from "../../channels/search_channel";
import I18n from '../../i18n-js/index.js.erb';

const MENUSTATE = "menu";
function menuState(){
  let ms = url.getParamOr(MENUSTATE,{cat: [], subcat: {}});
  if(!ms['subcat'])ms['subcat']={};
  if(!ms['cat'])ms['cat']=[];
  return ms;
}

function updateActiveStateCatWith(ms){
  document.querySelectorAll(".navLink").forEach( (e,i) => {
    if(ms.cat.includes(e.textContent)) select(e);
    else unselect(e);
  });
}
function updateActiveStateCat(){ updateActiveStateCatWith(menuState()); }


function select(element){
  if(element.classList.contains('catmob')){
    element.parentNode.classList.add('active');
  } else {
    element.classList.add('select');
  }
}

function unselect(element){
  if(element.classList.contains('catmob')){
    element.parentNode.classList.remove('active');
  } else {
    element.classList.remove('select');
  }
}

function onClickCategorie(element){
  let categorie = element.currentTarget.innerText;
  let ms = menuState();

  if(ms.cat.includes(categorie)) {
    if(!bigMenu.isReopenClick()) ms.cat.deleteSave(categorie);
  } else ms.cat.push(categorie);

  //Update BigMenu open
  if(bigMenu.isReopenClick())bigMenu.open();

  if (ms.cat.length < 1) {
    ms.cat = [];
    document.querySelectorAll(".souscategorie").forEach((e, i) => {
      e.classList.remove("select");
    });
  }

  if(JSON.stringify(ms) != JSON.stringify(menuState())) url.addParam(MENUSTATE,ms);
  updateActiveStateCatWith(ms);
}

function updateProductsFilterChipFromParams(){
  let subcat = menuState().subcat;

  Object.keys(subcat).forEach((key,i) => {
    //add line
    let menufilter = document.querySelector(".menu-filter[group="+key+"]");
    if(!menufilter){
      menufilter = document.createElement('div');
      menufilter.classList.add('menu-filter');
      menufilter.setAttribute('group',key);
      let menusfilter = document.querySelector(".menus-filter");
      if(menusfilter)menusfilter.append(menufilter);
    }

    //add group if needed
    let group_chips = document.querySelector(".menu-filter .chip[group=\""+key+"\"]");
    if(!group_chips){
      let group_chips = document.createElement('div');
      group_chips.classList.add("chip");
      group_chips.setAttribute('group',key);
      group_chips.textContent = I18n.t("menu.group."+key+".title");
      menufilter.append(group_chips);
    }

    //add elements
    subcat[key].forEach((element,i) => {
      let chip = menufilter.querySelector(".chip[element=\""+element+"\"]");
      if(!chip){
        let chip = document.createElement('div');
        chip.classList.add("chip");
        chip.setAttribute('group_name',key);
        chip.setAttribute('element',element);
        chip.textContent = I18n.t("menu.group."+key+".elements."+element+".title");
        let i = document.createElement('i');
        i.classList.value = "close material-icons";
        i.setAttribute('sub',key);
        i.setAttribute('cattype',element);
        i.textContent = "close";
        chip.append(i);
        menufilter.append(chip);
        addDeleteAction(chip);
      }
    });
    //remove elements
    menufilter.querySelectorAll(".chip").forEach((chip,i)=>{
      let chip_name = chip.getAttribute('element');
      if(chip_name){
        if(! subcat[key].includes(chip_name)){
          chip.remove();
        }
      }
    });
  });

  //remove key
  document.querySelectorAll(".menus-filter .chip[group]").forEach((group_chip,i)=>{
    let group = group_chip.getAttribute('group');
    if(group){
      if(!subcat[group]){
        group_chip.remove();
        document.querySelector(".chip[group_name="+group+"]").remove();
      }
    }
  });
}

function addDeleteAction(jqtarget){
  if(jqtarget.length == undefined) jqtarget.addEventListener('click',onClickSubCategorie);
  else jqtarget.forEach((e, i) => { e.addEventListener('click',onClickSubCategorie); });
}

document.addEventListener('turbolinks:load', ()=> {addDeleteAction(document.querySelectorAll(".menus-filter .chip i"))});

function onClickSubCategorie(element){
  let ms = menuState();

  if(element.target.getAttribute('param_to_delete')){
    delete ms[element.target.getAttribute('param_to_delete')];
    url.addParam(MENUSTATE,ms);
  } else {
    let sub = element.target.getAttribute('sub');
    let type = element.target.getAttribute('cattype');

    //if no categorie selected, select HOMME/ENFANT/FEMME
    if(ms.cat.length == 0){
      document.querySelectorAll(".navLink").forEach((e, i) => {
        e.classList.add("select");
        ms.cat.push(e.innerText);
      });
      ms.cat = ms.cat.uniq();
    }

    //create subcat categorie if needed and toogle click information
    if(ms.subcat[sub] == undefined)ms.subcat[sub] = [type];
    else ms.subcat[sub].toogleSave(type);

    if(ms.subcat[sub].length > 0){//avoid readding a '/^tou[t|s]/' when it's toogle off
      //if type start match tout/toutes/tous remove every other else remove tout/toutes/tous
      if(type.match(/^tou[t|s]/))ms.subcat[sub] = [type];
      else ms.subcat[sub].deleteIfSave(/^tou[t|s]/);
    }

    if(ms.subcat[sub].length == 0)delete ms.subcat[sub]; //remove subcat if no types

    url.addParam(MENUSTATE,ms);//update url
    updateActiveStateSubCat(); //update view
    bigMenu.close();
    //tabfilter.close();
  }

  updateProductsFilterChipFromParams();
  if("/products" != window.location.pathname) window.location.pathname = "/products";
  else search_channel.products(url.getParam('search'),ms);
}

function updateActiveStateSubCat(){
  let ms = menuState();

  document.querySelectorAll(".souscategorie").forEach((sc,i) => {
    if(ms.subcat[sc.getAttribute("sub")] && ms.subcat[sc.getAttribute("sub")].includes(sc.getAttribute("cattype"))){
      sc.classList.add("select");
    } else {
      sc.classList.remove("select");
    }
  });
}


let tabfilter = undefined;
function load(){
  let modalSubCatNav = document.querySelector("#modalSubCatNav");
  if(modalSubCatNav){
    h.loadOnReady(M.Modal,modalSubCatNav, (modalSubCat) => {
      tabfilter = modalSubCat;
      h.instanceAnd(M.Tabs,document.querySelector("#mcattabs"), (mcattabs)=>{
        modalSubCat.options.onOpenEnd = () => {
          let modalSubCatNav = document.querySelector("#modalSubCatNav");
          mcattabs.updateTabIndicator();
          modalSubCatNav.style.height = modalSubCatNav.clientHeight + "px"; //fixed height
        }
      });
    });
  }
  updateActiveStateSubCat();
  document.querySelectorAll(".souscategorie").forEach((e, i) => {
    e.addEventListener('click',onClickSubCategorie);
  });
  updateActiveStateCat()
  document.querySelectorAll(".navLink").forEach((e, i) => {
    e.addEventListener('click',onClickCategorie);
  });
}

export default {
  load, menuState
}
