
function loadOnReady(type, query, callback){(() => {
  instanceAnd(type, query, callback);
})();}

function getInstance(type, query){
  if(query !== undefined){
    let instance = type.getInstance(query);
    if(instance == undefined) instance = type.init(query);
    return instance;
  } else
    return undefined;
}

function instanceAnd(type, query, callback){
  let T = getInstance(type,query);
  if(T !== undefined) callback(T);
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  getInstance,loadOnReady,instanceAnd,sleep
}
