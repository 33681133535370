import cb from '../src/utils/channel_base';
import consumer from "./consumer";
import h from "../src/utils/helper";
let onChannelUp = [];
class ChannelParent {
  constructor(channel_name) {
    let self = this;
    this.isChannelUp = false;
    this.channel = consumer.subscriptions.create(channel_name, {
      connected() {
        document.querySelectorAll(".activeOnChannelUp").forEach((e, i) => {e.removeAttribute('disabled')});
        this.isChannelUp = true;
        while (onChannelUp.length > 0) {
          onChannelUp.pop()();
        }
      },
      disconnected() {
        document.querySelectorAll(".activeOnChannelUp").forEach((e, i) => {e.setAttribute('disabled', true)});
      },
      received(data){ self.on_received(data); }
    });
    return self;
  }

  on_received(data){
    if(data.msg){
      if(typeof(data.msg) == "string")this.toast(data.msg);
      else data.msg.forEach((m) => this.toast(m));
    }
    if(data.reload){
      h.sleep(2000).then(()=>{
        location.reload();
      });
    }
  }

  toast(m) {
    if(m != undefined){
      if(m.length > 300){
        document.querySelector("#bigtoasthtml").textContent = "";
        document.querySelector("#bigtoasthtml").appendChild(m);
        bigtoast.open();
      } else {
        M.toast({'html': m});
      }
    }
  }

  onChannelUp(callback){
    return onChannelUp.push(callback);
  }
}

export { ChannelParent };
