import cb from '../src/utils/channel_base';
const ChannelParent = require('./channel_parent').ChannelParent;

class SearchChannel extends ChannelParent {
  constructor() {
    let self = super("SearchChannel");
    self.list = { query: [], products: [], change: []};
    return self;
  }

  on_received(data){
    switch (data.action) {
      case "products":
        if(this.list.products.length == data.query_number)cb.execList(this.list.change,data);
        this.list.products[data.query_number-1] = undefined;
        break;
      case "query":
        //execute only last request
        if(this.list.query.length == data.query_number)this.list.query[data.query_number-1](data);
        this.list.query[data.callback_id-1] = undefined;
        break;
      default:
        console.warn("Je ne connais pas l'action "+data.action);
    }

    super.on_received(data);
  }

  query(text, callback){
    this.channel.send({'action': 'query', 'text': text, 'query_number': this.list.query.push(callback)});
  }

  products(text, filters){
    this.channel.send({'action': 'products', 'text': text, 'filters': filters, 'query_number': this.list.products.push("")});
  }

  listenToChange(change) { cb.listenToList(change,this.list.change); }
  clearToChange(id){ cb.clearToList(id,this.list.change); }
  clearAllChange() { this.list.change = []; }
}

export let search_channel = new SearchChannel();
